import {PlatformResponseInterface} from "@revolve/meta-data/roots/platform-microservice/response";
import * as PlatformActions from '@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.actions';
import {Action, createFeatureSelector, createReducer, createSelector, on} from "@ngrx/store";
import {env} from "@revolve-app/env/env";
import IpSession from "@revolve/meta-data/metadata/interfaces/IpSession";

export const PLATFORM_SETTINGS_FEATURE_NAME = 'PLATFORM SETTINGS FEATURE'

export interface PlatformState {
  platform: PlatformResponseInterface | null
  ipSession: IpSession | null
  allPlatforms: PlatformResponseInterface[]
}

export const platformInitialState: PlatformState = {
  platform: {
    id: 1,
    _uuid: env.platformId,
    services: {},
    name: ''
  },
  allPlatforms: [],
  ipSession: null
}

export const platformReducer = createReducer(
  platformInitialState,
  on(PlatformActions.platformSettingsGetByIdSuccess, (state, {payload}) => ({
    ...state,
    platform: payload.platform,
    ipSession: payload.ipSession,
  })),
  on(PlatformActions.getAllPlatformsRequestSuccess, (state, {payload}) => {
    return{
      ...state,
      allPlatforms: payload.data
    }

  }),

);


export function platforms(state: PlatformState, action: Action){
  return platformReducer(state, action);
}



export const selectPlatformState = createFeatureSelector<PlatformState>(PLATFORM_SETTINGS_FEATURE_NAME)


export const selectPlatformSettings = createSelector(
  selectPlatformState,
  (state: PlatformState) => state.platform
);
export const selectPlatformSettingsServices = createSelector(
  selectPlatformState,
  (state: PlatformState) => state.platform?.services
);
export const selectIpSession = createSelector(
  selectPlatformState,
  (state: PlatformState) => state.ipSession
);

export const selectAllPlatforms = createSelector(
  selectPlatformState,
  (state: PlatformState) => state.allPlatforms
);
