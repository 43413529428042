import {createAction, props} from "@ngrx/store";
import {PlatformResponseInterface} from "@revolve/meta-data/roots/platform-microservice/response";
import IpSession from "@revolve/meta-data/metadata/interfaces/IpSession";


export const PLATFORM_SETTINGS_GET_BY_ID_REQUEST = '[PLATFORM-SETTINGS] get by id dto start';
export const PLATFORM_SETTINGS_GET_BY_ID_SUCCESS = '[PLATFORM-SETTINGS] get by id dto success';
export const PLATFORM_SETTINGS_GET_ALL_PLATFORMS_REQUEST_START = '[PLATFORM-SETTINGS] get all platforms start';
export const PLATFORM_SETTINGS_GET_ALL_PLATFORMS_REQUEST_SUCCESS = '[PLATFORM-SETTINGS] get all platforms success';


export const platformSettingsGetByIdSuccess = createAction(
  PLATFORM_SETTINGS_GET_BY_ID_SUCCESS,
  props<{payload: {platform: PlatformResponseInterface, ipSession: IpSession} }>()
);
export const platformSettingsGetByIdRequest = createAction(
  PLATFORM_SETTINGS_GET_BY_ID_REQUEST,
  props<{id: string}>()
);

export const getAllPlatformsRequestStart = createAction(
  PLATFORM_SETTINGS_GET_ALL_PLATFORMS_REQUEST_START,
);

export const getAllPlatformsRequestSuccess = createAction(
  PLATFORM_SETTINGS_GET_ALL_PLATFORMS_REQUEST_SUCCESS,
  props<{payload: any}>()
);
